// 
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

// default theme colors - these are used for backgrounds, buttons etc.

// these colors are a linear "lightness" ramp from white to Rh_dark
$white: #FFFFFF !default;
$black: #000000 !default;
$nearblack: #141414;
$rh_yellow_dark: #f0c000ff; // rheologic yellow dark
$rh_yellow_light: #fff5cdff; // rheologic yellow light

// nice color palette using grey and rh_dark with 2 complementary colors:
/* CSS */
//--gainsboro: hsla(0, 0%, 87%, 1);
//--rich-black-fogra-29: hsla(203, 32%, 8%, 1);
//--united-nations-blue: hsla(216, 80%, 64%, 1);
//--saffron: hsla(48, 100%, 47%, 1);
//
///* SCSS HEX */
//$gainsboro: #ddddddff;
//$rich-black-fogra-29: #0e161bff;
//$united-nations-blue: #5995edff;
//$saffron: #f0c000ff;
//
///* SCSS RGB */
//$gainsboro: rgba(221, 221, 221, 1);
//$rich-black-fogra-29: rgba(14, 22, 27, 1);
//$united-nations-blue: rgba(89, 149, 237, 1);
//$saffron: rgba(240, 192, 0, 1);


// start yellow
//$gray-100: $white !default;
//$gray-200: $white !default;
//$gray-200: #fff3c3ff !default;
//$gray-300: #ffeda5ff !default;
//$gray-400: #ffe787ff !default;
//$gray-500: #ffe169ff !default;
//$gray-600: #ffdb4bff !default;
//$gray-700: #ffd52dff !default;
//$gray-800: #ffcf0fff !default;
//$gray-900: #f0c000ff !default;
// end yellow

// gray levels are a bit arbitrary - these are used throughout the theme in various places; e.g. gray-700 is the default color for dropdown links. Rather than messing up the colors here, color for individual variables are overwritten further down this file; e.g. $dropdown-link-color: $black
$gray-100: $white !default;
$gray-200: #fcfcfc !default;
$gray-200: #f8f8f8 !default;
$gray-300: #f5f5f5 !default;
$gray-400: #f1f1f1 !default;
$gray-500: #eeeeee !default;
$gray-600: #ebebeb !default;
$gray-700: #e7e7e7 !default;
$gray-800: #e4e4e4 !default;
$gray-900: #dddddd !default;
// end gray


// $primare is the link color for hyperlinks (menus, buttons,...)
//$primary: $nearblack !default;
// $primary: #2a52be; // cerulean blue
// $primary: #034694; // chelsea blue
// $primary: #0E3386; // cubs blue
$primary: $rh_yellow_dark !default;
$primary2: #00428c !default; // porto blue
//$secondary: $gray-700 !default;
$secondary: #5c5c5c !default;
$primary-desat: #b4b4b4 !default; // NEW

// link color
$link-color: $primary2 !default;

// Typography
//
// this is the basic text color (default $gray-600)
$text-muted: #1f1f1f !default;

$text-muted-on-gray: #696969;

// Navbar colors
$navbar-light-color: $text-muted-on-gray; // text color for light navbar
$navbar-light-hover-color: $nearblack; // hover text color for light navbar

// Dropdown link colors (menu)
$dropdown-link-color: lighten($primary, 40%) !default;

// form settings
$input-border-color: $gray-700 !default;

// carousel control color is white by default
$carousel-control-color:    $rh_yellow_dark !default;
$carousel-control-width:    15% !default;
$carousel-control-opacity:  .6 !default;

// redefine shadows a bit darker than the original theme
// this applies to cards, menues, modals and similar "hover" elements
$box-shadow-light: 0 0.5rem 1.5rem fade-out($black, .85) !default; // was 0.95
$box-shadow-light-lg: 0 1.5rem 4rem fade-out($black, .85) !default; // was 0.95

$box-shadow-dark: 0 0.5rem 1.5rem fade-out($black, .55) !default; // was 0.85
$box-shadow-dark-lg: 0 1.5rem 4rem fade-out($black, .55) !default; // was 0.85

$box-shadow-lift: 0 1rem 2.5rem fade-out($black, .8), 0 .5rem 1rem -.75rem fade-out($black, .8) !default;
$box-shadow-lift-lg: 0 2rem 5rem fade-out($black, .8), 0 .5rem 1rem -.75rem fade-out($black, .85) !default;

// extra small font size
$xs-font-size: 70%;