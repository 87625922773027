// 
// user.scss
// Use this to write your custom SCSS
//

// navbar background
.navbar-light {
  @extend .bg-gray-900;
  padding-top: 0;
  padding-bottom: 0.5rem;
}

// navbar logo padding
.navbar-brand-img {
  padding-top: 0.5rem;
  max-height: 3.5rem;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.navbar-brand {
  img:hover {
    //filter: none;
    animation: grayblend 3s infinite;
  }
}

@keyframes grayblend {

  // blending effect for logo
  0% {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }

  45% {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }

  55% {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }

  100% {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}

// navlink color for links
.dropdown-link {
  color: lighten($nearblack, 40%);

  @include hover-focus {
    color: $nearblack;
    font-weight: bold;
  }
}

.dropdown-item {
  color: lighten($nearblack, 40%);

  @include hover-focus {
    color: $nearblack;
    font-weight: bold;
  }
}

// custom styles for dark badges on landing page
// Basic badge settings for defined colors are in _badge.scss, here we define some special classes for the landing page to work well the the background

.badge-pill {
  border-width: 0.2px;
  border-style: solid;
  border-color: lighten($primary-desat, 5%);
}

// active, highlighted badge nearblack
.badge-nearblack-soft.active {
  background-color: $rh_yellow_dark;
  color: $nearblack;

  @include hover-focus {
    //background-color: darken($rh_yellow_dark, 5%);
    background-color: $rh_yellow_dark;
    color: $nearblack;
  }
}

// not-active badge nearblack with custom hover
.badge-nearblack-soft {
  color: $nearblack;

  @include hover-focus {
    background-color: $rh_yellow_dark;
  }
}

.checkbutton {
  position: relative;
  min-width: 35px;
  padding: 0 5px;
}

// span contains the text of the pill
.checkbutton span {
  left: 0px;
  position: relative;
  transition: all 0.2s ease-out 0s;
}

// the icon on the checkbutton
.checkbutton i {
  width: 18px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -9px;
  line-height: 18px;
  opacity: 0.0; // not visible by default
  transition: all 0.2s ease-out 0s;
}

.checkbutton:hover span {
  // shift right on hover
  left: 8px;
}

.checkbutton:hover i {
  // make visible and shift on hover
  opacity: 1;
  left: -5px;
}


// special border around index cards
.cardborder {
  border: auto;
  //border-color: lighten($secondary, 50%);
  border-color: $dark;
  border-width: thin;
}

// for very small screens reduce the display-3 text size used for the display on index.html
@media screen and (max-width: 400px) {
  .display-3 {
    font-size: 26px;
  }
}

@media screen and (max-width: 400px) {
  .lead {
    font-size: 16px;
  }
}

// text color for alert box
.alert-dark {
  color: $nearblack;
}

// ---------- buttons

// primary buttons 
.btn-primary {
  color: $nearblack; // text color
  background-color: desaturate($primary, 5%);

  box-shadow: 0 0 0 0 transparentize($primary, 1);
  animation: pulse 6s infinite; //slow pulse animation

  @include hover-focus {
    color: $black;
    background: $primary;
  }
}

@keyframes pulse {

  // percentages of animation time
  0% {
    //box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 0 0 transparentize($primary, 0.3);
  }

  50% {
    //box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px transparentize($primary, 1);
  }

  100% {
    //box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 transparentize($primary, 1);
  }
}

// fade out "typed" customer text (about page)
// NOTE: animation duration here must correspond with fadeOutDuration in partners-text-box.html
// FIXME: the transform: scale() part below does not work
.typed-fade {
  opacity: 1;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes fade {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.0, 1.0);
    -moz-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }

  15% {
    opacity: 1;
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.0, 1.0);
    -moz-transform: scale(1.0, 1.0);
    transform: scale(1.0, 1.0);
  }
}

// ---------- form styling

.form-label-group>label {
  color: $primary-desat;
  font-size: $small-font-size;
}

// ---------------- styling for image gallery

// set postitioning of div to relative to position overlay and image properly
.img_gal {
  position: relative;
}

// images in image gallery
.img_gal img {
  display: block;
  padding: 0.25rem;
  max-width: 100%;
  border-radius: $border-radius-lg;
  cursor: zoom-in;
}

// 

// overlay an icon on the image
.img_gal .fe-zoom-in {
  transition: color 1.0s ease;
  transition: font-size 1.0s ease;
  position: absolute;
  bottom: 10px;
  right: 30px;
  color: lighten($rh_yellow_dark, 30%);
  text-shadow: 0 0 10px #000; // adds an outline to make the visibility better on light background 
  pointer-events: none; // ignores clicks
}

// hover effect for icon overlay
.img_gal:hover .fe-zoom-in {
  transition: color .4s ease;
  transition: font-size .4s ease;
  color: $rh_yellow_dark;
  font-size: 1.3em;
}

.carousel-item {
  img {
    max-height: 90vh;
    object-fit: scale-down; //see also contain and cover (https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit)
    width: 100%;
    max-width: 100vw;
    border-radius: $border-radius-lg;

    //@media (min-width: $bootstrap-sm) {
    //  height: 350px;
    //}
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
}

// -------------- styling for modals
.modal-dialog-gallery {
  max-width: 100%; // redefine max width to override modal-dialog
}

.modal-open {
  padding-right: 0px !important;
}

.body {
  padding-right: 0px !important; //prevents modal-open class from adding padding to the body
}

// viewport sizing helpers
.min-vh-70 {
  min-height: 70vh !important;
}

// style for images in articles using predefined bootstrap classes to make it more consistent and easier to use in the markdown files
// jx-image is the image class of the juxtapose js library
.artcl-img,
.jx-slider {
  @extend .img-fluid;
  @extend .rounded;
  @extend .p-0;
  @extend .shadow-light;
  border-color: $secondary  !important;
  border: 0px; // no border unless specified in the md
}

// style for the cookie notice div when there is no cookie set
.cookie-notice {
  z-index: 1000;
  box-sizing: content-box;
  padding: 0.5rem 1rem;
  display: none;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 2rem);
  background-color: rgba(255, 255, 255, 0.801);
  //color: rgba(255,255,255,0.8);
  line-height: 1;
}

// add a symbol to external links; class for external links is set by jekyll plugin
.external-link {
  @extend .fe;
  font-size: 0.6em;
  padding-left: 0.2em;
  vertical-align: super; // or sub or baseline
}

// style figcaption tags
figcaption {
  @extend .text-secondary;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: $small-font-size;
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.3em;
  line-height: 105%;
}

// overlay figures with a caption
.figcaption-gallery {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.675);
  text-align: center;
  line-height: 1;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
}

@media screen and (max-width: 600px) {
  .figcaption-gallery {
    display: none;
  }
}

// adjust modal footer for our "close" button style
.modal-footer {
  position: relative;
  margin-top: -2rem;
  bottom: 0rem;
  z-index: 1;
  border: 0px;
}

.card-img-top {
  height: auto;
}

// text label for cards - eg. 3D card or article card
.cardcaption {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: $gray-600;
  color: $text-muted-on-gray;
  line-height: 1;
  border-top-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
}

// rheologic style list with triange as list icon

ul.rhlist {
  list-style-type: none;
  padding: 10px;
  margin: 0px;
}

ul.rhlist li {
  background-image: url('../img/rheo_misc/rh1_r.svg');
  background-repeat: no-repeat;
  background-size: 0.72em;
  background-position: 0 7px;
  padding-left: 25px;
  /* how much space to the text */
}

// specific footer styles
footer {
  color: $text-muted;
}

footer a {
  color: $text-muted;
}

footer a:hover {
  text-decoration: none;
}

// social icons override
.list-social-icon {
  max-width: 2.0em;
}

// markdown / kramdown / html interaction introduces p tag in li when parsing with markdown="1"
// re-define p so that we don't introduce bad spacing in these lists
li p {
  margin-bottom: 0px;
}

// custom checkbox styling
// check box display is usually by user agent - so we need to use "custom-" classes
// NOTE: checkbox styling is complex, because usually done by user agent and requires ::before / ::after elements
// https://stackoverflow.com/questions/44279398/customize-bootstrap-checkboxes
// https://stackoverflow.com/questions/37493852/how-can-i-change-the-color-of-bootstrap-checkbox/37498812
// https://www.w3schools.com/bootstrap4/bootstrap_forms_custom.asp
// https://stackoverflow.com/questions/60196212/bootstrap-4-how-can-i-change-the-color-of-checkbox-simple-way

.custom-control {
  line-height: 1.8rem;
}

.custom-control-input:focus~.custom-control-label::before {
  border-color: $primary  !important;
  //box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
  box-shadow: $box-shadow-light  !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: $secondary  !important;
  background-color: $primary  !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: $secondary  !important;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  color: $text-muted;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: lighten($primary, 40%) !important;
  border-color: lighten($primary, 40%) !important;
}

// box around checkmark
.custom-control-label::before {
  width: 1.3rem;
  height: 1.3rem;
  left: -1.9rem;
  top: 0.2rem;
}

// checkmark
.custom-control-label::after {
  position: absolute;
  top: 0.0rem;
  left: -2.12rem;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  transition: all .2s ease-in;
}

.custom-checkbox .custom-control-label::before {
  //border-radius: 0.1rem;
  border: 1px solid $secondary;
  border-radius: $border-radius-sm;
}

/* slider for picking an area for the cost estimation */
.areaslider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 40px;
  /* Specified height */
  background: transparent;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.5;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.areaslider:hover,
.areaslider:focus,
.areaslider:active {
  opacity: 1;
  /* Fully shown on mouse-over */
}

// see here for slider styling: https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
.areaslider::-webkit-slider-thumb {
  // the symbol that is being dragged
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -14px;
  /* less than half the height in chrome; ff and IE automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  /* Add cool effects to your sliders! */
}

input[type=range]::-webkit-slider-runnable-track {
  // the slider track along which the symbol is dragged
  width: 100%;
  height: 12px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $primary;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

// control spacing in numbers - e.g. grouped in 3 digits, space must be present
.spacednumber {
  word-spacing: -0.08em;
  white-space: nowrap;
}


// kramdown footnote styling
.footnotes {
  font-size: 1rem;
  margin: 20px;
  @extend .text-muted;
}

.footnotes p {
  margin: 0px;
}

a.footnote:before {
  content: "[";
}

a.footnote:after {
  content: "]";
}

// div for three js
.threed {
  width: 100%;
  //height: 100%;
  min-height: 80vh; // must correspond to canvas height in three js file
  border-radius: $border-radius-lg;
  overflow: hidden;
  align-self: center;
  position: relative;
}

.scale {
  position: absolute;
  font-family: Arial;
  top: 10px;
  left: 30px;
  pointer-events: none;
}

.threetitle {
  position: absolute;
  top: 10px;
}

.moveGUI {
  position: absolute;
  top: 10px;
  right: 5px;
}

// overlay canvas with a footer
.canvas-footer {
  position: absolute;
  color: $white;
  //margin-left: auto;
  //margin-right: auto;
  width: 100%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.675);
  //text-align: left;
  font-size: $xs-font-size;
  //line-height: 1;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  pointer-events: none;
}

// info button to display legend over three.js canvas
.infobutton {
  position: absolute;
  right: 20px;
  bottom: 80px;
  font-size: 200%;
}

.legendbox {
  position: absolute;
  //width: 60%;
  color: $white;
  bottom: 10%;
  background-color: rgba(0, 0, 0, 0.85);
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: $border-radius-lg;
}

.legendbox.collapsing {
  //disable animation
  transition: none !important;
}

.fullscreenbutton {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 200%;
}

.compass {
  position: absolute;
  display: block;
  bottom: 40px;
  left: 30px;
  width: auto;
  height: 7.5vh;
  text-align: center;
  object-fit: contain;
  pointer-events: none;
}

.N-ring {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}

.N-dir {
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0vh;
  width: auto;
  height: 7.5vh;
  transform: rotate(0deg);
  pointer-events: none;
}

.W-dir {
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0vh;
  width: auto;
  height: 7.3vh;
  transform: rotate(0deg);
  pointer-events: none;
}

@media (display-mode: fullscreen) {
  .fe-maximize {
    display: none
  }
}

@media (display-mode: fullscreen) {
  .fe-minimize {
    display: inline !important
  }
}

.fe-minimize {
  display: none;
}

.fe-toggle {
  position: relative;
  //transition: color 1.0s ease;
  transition: font-size 1.0s ease;
  //color: lighten($rh_yellow_dark, 30%); 
  text-shadow: 0 0 5px #ffffff; // adds an outline to make the visibility better on the background 
}

// hover effect for icon overlay
.fe-toggle:hover {
  position: relative;
  //transition: color .6s ease;
  transition: font-size .4s ease;
  //color: $rh_yellow_dark;
  font-size: 1.3em;
  cursor: pointer;
}

// adjust canvas footer message when using touch devices (finger, coarse) or pointer devices (fine) as primary input method
@media (pointer: fine) {
  .canvas-footer-left::after {
    content: "Rotate: Left Mouse Button | Pan: Shift + Left Mouse Button | Zoom: Mouse Wheel"
  }
}

@media (pointer: coarse) {
  .canvas-footer-left::after {
    content: "Rotate: Swipe | Pan: 2-Finger Swipe | Zoom: Pinch"
  }
}

// show helpful text at the right side bottom of the canvas
// Show a message when the screen is very small
.canvas-footer-right::after {
  content: "Change to a larger screen for a better experience."
}

// message for larger screens
@include media-breakpoint-up(md) {
  .canvas-footer-right::after {
    content: "Scroll page: use screen edges or cursor keys"
  }
}

// message in fullscreen mode
@media (display-mode: fullscreen) {
  .canvas-footer-right::after {
    content: "Press the ESC key to exit fullscreen mode"
  }
}


// ------------------------- start 3D loader spinner animation (for three js 
.loadingspinner {
  // show a spinner while loading 3D files
  position: absolute !important;
}

#loading-screen {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary-desat;
  opacity: 1;
  transition: 1s opacity;
}

#loading-screen.fade-out {
  opacity: 0;
}

#loading {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: $primary;
  -webkit-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}

#loading:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: $rh_yellow_light;
  -webkit-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}

#loading:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: $secondary;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// ------------------------- end 3D loader spinner animation (for three js 

// ------------------------- start juxtapose js library overrides

// workaround for broken "showcredits false" for the juxtapose library
a.jx-knightlab {
  visibility: hidden;
}

div.jx-control {
  color: $rh_yellow_dark;
}

div.jx-arrow {
  color: $rh_yellow_dark;
}

// ------------------------- end juxtapose js library overrides

.icon-lang {
  color: $primary-desat;
}


// maintenance progress bar
.round-time-bar {
  margin: 1rem;
  overflow: hidden;
}

.round-time-bar div {
  height: 5px;
  animation: roundtime calc(var(--duration) * 1s) linear forwards;
  transform-origin: left center;
  background: $secondary;
  height: 5px;
}

@keyframes roundtime {
  to {
    /* More performant than `width` */
    transform: scaleX(0);
  }
}

.maintenance {
  visibility: visible;
  opacity: 1;
  animation: fadeout calc(var(--duration) * 1s) ease-in;
}

@keyframes fadeout {
  to {
    opacity: 0
  }
}